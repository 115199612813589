<template>
    <div id="index">
        <div id="main_statement_container">
            <div id="main_statement">
                <h1><span class="bold">Powerful&nbsp;Tools&nbsp;to </span><span class="italic">Analyze&nbsp;&amp;&nbsp;Automate</span><span class="bold"> Trading&nbsp;Strategies</span></h1>
                <div class="video">
                    <iframe width="100%" height="300" src="https://www.youtube.com/embed/ouhxTF3ss3A?si=4PLtbxKoZIAUswj8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-v-bba15240=""></iframe>
                </div>
            </div>
        </div>
        <div id="products_container">
            <div id="products_title_container">
                <div id="products_title">
                    <div class="title_products">
                        <h1>Products</h1>
                    </div>
                </div>
            </div>
            <div id="products_tvio">
                <div id="tvio_area_container">
                    <div><div id="tvio_menu_anchor"></div></div> <!-- @JA Use this technique to anchor when the parent class is using flexbox (https://stackoverflow.com/questions/10732690/offsetting-an-html-anchor-to-adjust-for-fixed-header) -->
                    <div id="tvio_area">
                        <div id="tvio_info">
                            <h2>TradingView Input Optimizer&nbsp;<version>v{{ version }}</version></h2>                            
                            <subheader>Analyze &amp; Automate Strategies From TradingView</subheader>
                            <h3 class="tvio_fp">Test input ranges automatically and compile the data into an easy to analyze report with analysis tools and import/export support.</h3>
                            <button_container>
                                <a :href="`/downloads/tradingviewinputoptimizer_v${version}.zip`"><button class="button button_download_free">Download Free</button></a>
                                <router-link to="/sign-up" @click="alertLicense"><button class="button button_buy_license">Buy License</button></router-link>
                            </button_container>
                        </div>
                        
                        <div class="video">
                            <iframe class="light_border" width="100%" height="300" src="https://www.youtube.com/embed/ouhxTF3ss3A?si=4PLtbxKoZIAUswj8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-v-bba15240=""></iframe>
                        </div>
                        <div class="product_features_area">
                            <div class="product_features_title">Product Features:</div>
                        </div>
                        <div id="tvio_features">
                            <div class="tvio_feature">
                                <div class="img_placeholder light_border supports_multiple_inputs"></div>
                                <h4>Supports Multiple Inputs</h4>
                                <h5>Simply click on the input you want to optimize in your strategy &amp; set the start, end &amp; step value</h5>
                            </div>
                            <div class="tvio_feature">
                                <div class="img_placeholder light_border reporting">
                                    <!-- <video height="250" loop muted defaultmuted preload="none" poster="../assets/icon-32.png" onmouseover="this.play();this.setAttribute('controls','controls');" onmouseout="this.load();this.removeAttribute('controls');">
                                        <source src="../assets/feature_reporting_optimized.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video> -->
                                </div>
                                <h4>Reporting</h4>
                                <h5>After or during the scan you can view the report data at any time in a table format that can easily be sorted</h5>
                            </div>
                            <div class="tvio_feature">
                                <div class="img_placeholder light_border multidimensional_heatmap">
                                    <!-- <video height="250" loop muted defaultmuted preload="none" poster="../assets/icon-32.png" onmouseover="this.play()" onmouseout="this.load()">
                                        <source src="../assets/sven.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video> -->
                                </div>
                                <h4>Multidimensional Heatmap</h4>
                                <h5>View the report data visually with the heatmap even if your search has more than two inputs</h5>
                            </div>
                            <div class="tvio_feature">
                                <div class="img_placeholder light_border per_strategy_scan_preferences">
                                    <!-- <video height="250" loop muted defaultmuted preload="none" poster="../assets/icon-32.png" onmouseover="this.play()" onmouseout="this.load()">
                                        <source src="../assets/VerifiableTerrificHind.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video> -->
                                </div>
                                <h4>Per Strategy Scan Preferences</h4>
                                <h5>The optimizer automatically remembers your input settings for different strategies</h5>
                            </div>
                            <div class="tvio_feature">
                                <div class="img_placeholder light_border supports_tickers_and_timeframes">
                                    <!-- <video height="250" loop muted defaultmuted preload="none" poster="../assets/icon-32.png" onmouseover="this.play();this.setAttribute('controls','controls');" onmouseout="this.load();this.removeAttribute('controls');">
                                        <source src="../assets/bear.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video> -->
                                </div>
                                <h4>Supports Tickers &amp; Timeframes</h4>
                                <h5>You can test your strategies against different timeframes &amp; different tickers to find the best sweetspots</h5>
                            </div>
                            <div class="tvio_feature">
                                <div class="img_placeholder light_border add_data_to_past_named_scans"></div>
                                <h4>Add Data To Past Named Scans</h4>
                                <h5>Keep track of your past scans by naming them with a unique identifier (Pro Version Only) &amp; add new data to those past scans</h5>
                            </div>
                            <div class="tvio_feature">
                                <div class="img_placeholder light_border random_delay_ranges_for_scans"></div>
                                <h4>Random Delay Ranges For Scans</h4>
                                <h5>Set a custom delay range in order to make your scans seem more authentic &amp; humanlike</h5>
                            </div>
                            <div class="tvio_feature">
                                <div class="img_placeholder light_border export_to_csv_and_json_formats"></div>
                                <h4>Export to CSV & JSON Formats</h4>
                                <h5>Export your report data for use in other applications in either CSV or JSON formats with the touch of a button</h5>
                            </div>
                            <div class="tvio_feature">
                                <div class="img_placeholder light_border deep_backtesting_support"></div>
                                <h4>Deep Backtesting Support</h4>
                                <h5>Test your strategies with all available data on Tradingview's deep backtesting feature.</h5>
                            </div>
                            <div class="tvio_feature">
                                <div class="img_placeholder light_border supports_multiple_input_types"></div>
                                <h4>Supports Multiple Input Types</h4>
                                <h5>Supports numerical, checkbox and dropdown input types.</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="pricing_area_container">
                    <div id="pricing_area">
                        <div id="pricing_text">Pricing</div>
                        <div id="pricing_table">
                            <div id="pricing_options">
                                <div id="pricing_trial">
                                    <h6>Trial</h6>
                                    <h7>Free</h7>
                                </div>
                                <div id="pricing_licensed">
                                    <h6>Licensed</h6>
                                    <h7>$300 for 6 months, then</h7>
                                    <h7>$50/mo. after</h7>
                                </div>
                            </div>
                            <div class="pricing_feature lightfull">Works on Windows &amp; Mac</div><div class="trial_section checked"></div><div class="licensed_section"></div>
                            <div class="pricing_feature half">Analyze Unlimited Iterations*</div><div class="trial_section limited"></div><div class="licensed_section"></div>
                            <div class="pricing_feature lighthalf">Use Your License Key On Up To 5 Machines</div><div class="trial_section"></div><div class="licensed_section"></div>
                            <div class="pricing_feature half">Perpetual Performance/Utility Updates Included</div><div class="trial_section"></div><div class="licensed_section"></div>
                            <div class="pricing_feature lighthalf">Free 30 Minute Coaching Session With The Developers**</div><div class="trial_section"></div><div class="licensed_section"></div>
                            <div class="pricing_feature half">Custom Named Searches***</div><div class="trial_section"></div><div class="licensed_section"></div>
                            <div class="button_area">
                                <button_container>
                                    <a :href="`/downloads/tradingviewinputoptimizer_v${version}.zip`"><button class="button button_download_free">Download Free</button></a>
                                    <router-link to="/sign-up" @click="alertLicense"><button class="button button_buy_license" >Buy License</button></router-link>
                                </button_container>
                            </div>
                            <div class="fine_print">
                                * Trial Mode Limited to 25 or Less Iterations Per Search <br />
                                ** Coaching Sessions Limited to English Only <br />
                                *** Ability to Name Searches &amp; View Reports By Name
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    </div>
</template>

<script>
export default {
  name: 'Index-Component',
  props: {
    version: String
  },
  methods: {
    anchorHashCheck() {
      if (window.location.hash === this.$route.hash) {
        const el = document.getElementById(this.$route.hash.slice(1))
        if (el) {
          window.scrollTo(0, el.offsetTop)
        }
      }
    },
    alertLicense(){
        alert("You must create an account to buy a license. After you log in, you will see the \"Buy Now\" button in your dashboard. ");
    }
  },
  mounted() {
    this.anchorHashCheck()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* .button_download_free{
        width:48%;
    }
    .button_buy_license{
        width:47%;
    } */
    #pricing_area_container{
        background-color: #f3f2ef;
    }
    .tvio_feature{
        max-width: 500px;
        padding: 0px 5px;
    }
    .product_features_area{
        display:flex;
        width:100%;
        /* background-color: red; */
        padding:10px 0;
        justify-content: center;
    }
    .product_features_title{
        /* background-color: yellow; */
        padding-left:10px;
        /* margin-top:20px; */
        font-size: 22px;
        font-weight:bold;
        margin-bottom:-20px;
    }
    #pricing_area{
        padding-top:10px;
    }
    #pricing_text{
        /* margin: 10px 0; */
        font-size:22px;
        font-weight:bold;
        margin-bottom:-10px;
    }
    #products_container{
        width:100%;
        display:flex;
        flex-wrap: wrap;
        flex-flow: column;
        justify-content: center;
    }
    
    #products_tvio{
        background-color: #222;
    }
    .tvio_feature h4{
        font-weight: bold;
    }
    #products_title_container{
        /* background-color: red; */
        margin: 20px;
 
    }
    #products_title{
        max-width: 960px;
    }
    #products_title h1{
        /* background-color: purple; */
        font-weight:bold;
        font-size:28px;
        margin:0;
        /* padding-top:20px; */
        width:100%;
        max-width: 960px;
    }
    .bold{font-weight: bold;}
    .italic{font-style: italic;}
    #main_statement h1{
        font-size:32px;
        line-height: 36px;
        max-width: 600px;
    }
    /* @JA - Use this code if you want to have anchor links properly scroll to a location and offset correcly for fixed position headers */
    #tvio_menu_anchor::before {
        content: '';
        display: block;
        height: calc(-10px +var(--nav-height));
        margin-top: calc(10px +var(--nav-height)*-1);
        visibility: hidden;
    }
    #index {
        text-align: center;
        /* padding-top: var(--nav-height); */
        display:flex;
        flex-direction: column;
        justify-content: center;
        background-color: #f3f2ef;
    }
    #main_statement{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    #main_statement h1{
        width:100%;
    }
    h1 {
        margin: 20px 0px;
    }
    /* #main_statement .video{
        max-height:180px;
        max-width: 320px;
    } */
    .video{
        width:100%;
        margin: 5px;
        max-width: 500px;
    }
    video {
        object-fit:contain;
    }
    #main_statement .video{
        box-shadow: 0px 0px 10px #666;
    }
    video:not(:hover){
        box-shadow: none;
    }
    h2 {
        font-size: 28px;
        display:inline;
    }
    version {
        width: 100%;
        display:inline;
        /* margin-left:3px;
        margin-bottom: 10px; */
        font-size:14px;
        font-weight:bold;
    }
    h3 {
        font-size:12px;
        padding: 10px;
        display:block;
    }
    h4 {
        font-size: 22px;
        margin-bottom:5px;
    }
    h5 {
        line-height:18px;
    }
    h6 {
        display:block;
        width:100%;
        font-size:18px;
        font-weight:bold;
    }
    h7 {
        width:100%;
        font-size:14px;
        padding-top:4px;
    }
    #tvio_area {
        /* margin-top:10px; */
        padding:20px 0px;
        color:#e2e2e2;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    subheader{
        display:block;
        font-weight:bold;
        margin-top:10px;
        margin-bottom:10px;
    }
    .button {
        border: none;
        color: white;
        padding: 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        font-family: 'Ubuntu', Helvetica, sans-serif;
        margin: 4px;
        transition-duration: 0.4s;
        cursor: pointer;
        min-width: 140px;
    }

    .button_buy_license {
        background-color: rgb(0, 98, 52); 
        color: white;
        border: 2px solid #4CAF50;
    }
    .button_buy_license:hover {
        background-color: #4CAF50;
    }
    .button_download_free {
        background-color: rgb(212, 99, 0); 
        color: white; 
        border: 2px solid #fa7900;
    }
    .button_download_free:hover {
        background-color: #ff8000;
    }
    .button_container {
        display:flex;
        margin: 10px 0;
        justify-content: space-around;
    }
    .light_border{
        border: 1px solid #aaa;
        box-shadow: 0px 0px 4px #aaa;
    }
    .img_placeholder{
        /* width:100%; */
        /* width: 450px; */
        height: 250px;
        margin: 30px auto 10px auto;
        background-color:dodgerblue;
        /* animation-name:placeholder_animation_scaleup;
        animation-direction: reverse;
        animation-iteration-count: 1;
        animation-duration: 0.5s;
        animation-timing-function:ease-in-out; */
    }
    @keyframes placeholder_animation_scaleup {
        0% {transform:scale(1)}
        100% {transform:scale(1.1)}
    }
    @keyframes placeholder_animation_scaledown {
        0% {transform:scale(1)}
        100% {transform:scale(0.9)}
    }
    .img_placeholder:hover{
        animation-name:placeholder_animation_scaleup;
        animation-duration: 0.2s;
        animation-delay: 0s;
        animation-iteration-count:1;
        animation-direction:normal;
        animation-timing-function:ease-in-out;
        animation-fill-mode:forwards;
    }
    .supports_multiple_inputs{
        /* background: green url('../assets/inline_fields_with_names.gif'); */
        background: url('../assets/thumbnails/supports_multiple_inputs.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .reporting{
        /* background: green url('../assets/report_custom_names_in_controls.png'); */
        background: green url('../assets/thumbnails/reporting.png');
        background-position: 0% 63%;
        background-repeat: no-repeat;
    }
    .multidimensional_heatmap{
        background: url('../assets/thumbnails/multidimensional_heatmap.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .per_strategy_scan_preferences{
        background: url('../assets/thumbnails/per_strategy_scan_preferences.png');
        background-position: center;
        background-position: 3% 40%;
        background-repeat: no-repeat;
        /* background-size: cover; */
    }
    .supports_tickers_and_timeframes{
        background: url('../assets/thumbnails/supports_tickers_and_timeframes.png');
        background-position: center;
        background-position: 16% 40%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .add_data_to_past_named_scans{
        background: url('../assets/thumbnails/add_data_to_past_named_scans.png');
        background-position: center;
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .random_delay_ranges_for_scans{
        background: url('../assets/thumbnails/random_delay_ranges_for_scans.png');
        background-position: center;
        background-position: 100% 40%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .export_to_csv_and_json_formats{
        background: url('../assets/thumbnails/export_to_csv_and_json_formats.png');
        background-position: center;
        background-position: 3% 40%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .deep_backtesting_support{
        background: url('../assets/thumbnails/deep_backtesting_support.png');
        background-position: center;
        background-position: 60% 40%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .supports_multiple_input_types{
        background: url('../assets/thumbnails/supports_multiple_input_types.png');
        background-position: center;
        background-position: 3% 40%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .coming_soon{
        background: dodgerblue url('../assets/video_placeholder_tvio.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    #pricing_area{
        width:100%;
    }
    .trial_section, .licensed_section{
        display:none;
    }
    #pricing_table{
        margin-top:20px;
        color:white;
        display: flex;
        flex-wrap: wrap;
    }
    #pricing_options{
        width: 100%;
        display: flex;
    }
    #pricing_trial{
        text-shadow: 1px 1px 2px #222;
        /*color:#222;*/
        padding:20px 0;
        background-color:#fa7900;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        width:50%;
        border-right: 1px solid #ccc;
        /*border-top: 1px solid #ccc;*/
    }
    #pricing_licensed{
        text-shadow: 1px 1px 2px #222;
        /*border-top: 1px solid #ccc;*/
        /*color:#222;*/
        padding: 20px 0;
        background-color:dodgerblue;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;        
        width:50%;
    }
    .pricing_feature{
        padding:10px;
        width:100%;
        background-color:#222;
        text-shadow: 1px 1px 2px #222;
        font-weight:bold;
        /*border-bottom: 1px solid rgb(13, 231, 104);*/
    }
    .light{
        background-color: #333;
    }
    .fine_print{
        font-style: italic;
        text-align: left;
        color:#222;
        font-size: 12px;
        margin: 5px 0 10px 0;
        padding-left: 5px;
    }
    .lightfull{
        background-color:#357e38;
    }
    .half{
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,#222), color-stop(50%,#222), color-stop(50%,#317433), color-stop(100%,#317433));
    }
    .lighthalf{
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,#333), color-stop(50%,#333), color-stop(50%,#357e38), color-stop(100%,#357e38));
    }
    .button_area{
        width:100%;
    }
    @media all and (min-width: 500px) {
        h3{
            max-width:480px;
        }
    }
    @media all and (min-width: 960px) { /*DESKTOP*/
        .product_features_area{
            justify-content: flex-start;
        }
        #products_title{
            width:100%;
            max-width:960px;
            padding-left:10px;
            display: flex;
            /* background-color: red; */
        }
        .title_products{
            display:flex;
            /* background-color: yellow; */
            align-content: center;
        }
      
        #tvio_menu_anchor::before {
            content: '';
            display: block;
            height: var(--nav-height);
            margin-top: calc(var(--nav-height)*-1);
            visibility: hidden;
        }
        #index{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .video{
            margin: 0;
        }
        /* #main_statement{
            background-color:purple;
        } */
        #main_statement h1{
            /* background-color:goldenrod; */
            padding-right: 50px;
            font-size:48px;
            line-height: 48px;
        }
        #main_statement iframe{
            box-shadow: 0 0 10px #666;
        }
        /* #main_statement_container .video{
            padding-right:40px;
        } */
        #main_statement h1, #main_statement .video {
            width:50%;
        }
        .tvio_fp{
            padding:0;
            font-size: 12px;
            text-align: left;
            margin-bottom: 20px;
        }
        #main_statement_container{
            padding:40px 0px 20px 0px;
            width: 100%;
            display: flex;
            justify-content: center;
            /* border-bottom: 1px solid #ddd; */
        }
        #tvio_info{
            /* background-color: purple; */
            display: flex;
            flex-wrap: wrap;
            width:50%;
            align-items: center;
            padding: 40px 20px 40px 0px;
        }
        #tvio_area_container{
            margin-top: 20px;
            
            width:100%;
            display:flex;
            flex-direction: row;
            justify-content: center;
        }
        #tvio_area, #pricing_area{
            margin:20px 0px;
            
        }
        #products_title{
            text-align:left;
        }
        #products_title_container{
            
            display:flex;
            justify-content: center;
        }
        #products_title h1{
            font-weight:bold;
            font-size:28px;
            margin:0;
            /* padding-top:20px; */
        }
        #pricing_area{
            display:flex;
            flex-wrap: wrap;
        }
        #pricing_area h3{
            width:100%;
        }
        #pricing_options{
            display:flex;
            width:100%;
            justify-content: flex-end;
        }
        #pricing_trial, #pricing_licensed {
            width:20%;
        }
        #tvio_area .video{
            width:49%;
            /* padding-right:40px; */
        }
        #tvio_info h2{
            text-align: left;
        }
        #tvio_info version{
            width: auto;
            display: inline;
        }
        #tvio_info subheader{
            text-align: left;
            width:100%;
        }
        
        #main_statement{
            align-items: center;
        }
        #main_statement{
            width:960px;
        }
        #tvio_area h3{
            width:100%;
        }
        #tvio_features{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        #tvio_features .tvio_feature{
            width:49%;
        }
        
        .trial_section, .licensed_section{
            display:flex;
            align-items: center;
            justify-content: center;
            width:20%;
        }
        .trial_section:nth-child(odd),.licensed_section:nth-child(odd){
            background-color:#222;
        }
        .trial_section:nth-child(even),.licensed_section:nth-child(even){
            background-color:#333;
        }


        .trial_section.checked::after,.licensed_section::after{
            font-family: "Font Awesome 6 Free";
            font-size: 20px;
            font-weight: 900;
            content: "\f14a";
            color: white;
        }
        .trial_section.limited::after{
            content: "Limited*";
            font-size: 14px;

        }
        .pricing_feature{
            width: 60%;

        }
        .button_area{
            /* border-top:1px solid #222;
            background-color:white; */
            order: 2;
            width:40%;
        }
        #tvio_info button_container{
            display: flex;
        }
        #pricing_area_container{
            display: flex;
            justify-content: center;
        }
        #tvio_area{
            max-width: 960px;
        }
        #pricing_area{
            max-width: 960px;
        }
        #pricing_area button_container{
            display:inline;
            width: 100%;
            justify-content: space-evenly;
        }
        .fine_print{
            padding-left:15px;
            width:60%;
            display: flex;
            align-items: center;
            /*background-color: #fa7900;*/
            order: 1;
        }
        .light{
            background:none;
            background-color: #333;
        }
        .lighthalf{
            background:none;
            background-color: #333;
        }
        .lightfull{
            background:none;
            background-color: #333;
        }
        .half{
            background:none;
            background-color: #222
        }
        /*.has-submenu > a::after {
            font-family: "Font Awesome 6 Free";
            font-size: 12px;
            line-height: 16px;
            font-weight: 900;
            content: "\f078";
            color: white;
            padding-left: 5px;
        }*/
    }
</style>
